import { Injectable } from '@angular/core';
import { HttpService } from '@app/core/http';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { ToastrService } from 'ngx-toastr';

import { tap } from 'rxjs';

import { EnrollmentList } from '../actions/enrollment.action';

interface IEnrollmentStateModel {
  enrollmentList: IEnrollmentListData[];
  enrollmentListCount: number;
}

@State<IEnrollmentStateModel>({
  name: 'EnrollmentState',
  defaults: {
    enrollmentList: [],
    enrollmentListCount: 0,
  },
})
@Injectable()
export class EnrollmentState {
  constructor(private _http: HttpService, private _toastr: ToastrService) {}

  @Selector()
  static enrollmentList(state: IEnrollmentStateModel) {
    return state.enrollmentList;
  }

  @Selector()
  static enrollmentListCount(state: IEnrollmentStateModel) {
    return state.enrollmentListCount;
  }

  @Action(EnrollmentList)
  EnrollmentList(
    ctx: StateContext<IEnrollmentStateModel>,
    { param }: EnrollmentList
  ) {
    return this._http.post('user/list', param).pipe(
      tap((apiResult) => {
        const resultData = apiResult.response.dataset;
        ctx.patchState({
          enrollmentList: resultData.users,
          enrollmentListCount: resultData.total_rows,
        });
      })
    );
  }
}
